<div class="add-category-container">
  <div class="languages-container" *ngIf="languages?.length > 1">
    <div class="language" *ngFor="let language of languages; let idx = index">

      <div class="top">
        <div class="flag-wrapper" [ngClass]="{'flag-wrapper-selected': currentLanguageIdx === idx}">
          <img [src]="'https://cdn.mocli.fr/icon/flags/' + language?.key + '.svg'" alt="">
        </div>
  
        <div class="bar" [ngStyle]="{'background-color': stepStatusColors[getStatus(idx)]}"></div>
      </div>
      
      <div class="badge" [ngStyle]="{'background-color': (stepStatusColors[getStatus(idx)] + '4d'), 'color': stepStatusColors[getStatus(idx)]}">{{stepStatusLabels[getStatus(idx)]}}</div>
    </div>
  </div>
  
  <div class="input-container" [formGroup]="addCategoryFormGroup">
    <label for="name">Nom de la catégorie</label>
    <input [ngClass]="{'invalid-input': addCategoryFormGroup.controls.name.invalid && isSubmitted}" [style]="'margin-top: ' + (languages?.length > 1 ? '40px' : '12px')" formControlName="name" type="text" id="name" [placeholder]="currentLanguageIdx === 0 ? 'Écrire ici...' : ('À traduire : ' + nameByLang['fr'])">
    <div class="invalid-input-container">
        <span *ngIf="addCategoryFormGroup.controls.name.invalid" class="invalid-input-text">
            <span *ngIf="addCategoryFormGroup.controls.name?.errors?.required && isSubmitted">Champs requis. </span>
            <span *ngIf="addCategoryFormGroup.controls.name?.errors?.maxlength && isSubmitted">Taille maximale atteinte. </span>
            <span *ngIf="addCategoryFormGroup.controls.name?.errors?.alreadyExists && isSubmitted">Ce nom existe déjà. </span>
        </span>
    </div>
  </div>

  <div class="controls-data">
    <div class="btn btn-cancel" (click)="prevStep()">{{currentLanguageIdx === 0 ? 'Annuler' : 'Langue précédente'}}</div>
    <div class="btn btn-confirm" (click)="nextStep()">{{currentLanguageIdx + 1 === languages?.length ? 'Confirmer' : 'Langue suivante'}}</div>
  </div>
</div>