/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { OrderService } from '@mocli/MocliCommonLib';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, debounceTime, firstValueFrom, Subject, Subscription } from 'rxjs';
import { CodeHuntCode } from 'src/app/data/models/CodeHunt';
import { SimpleQuiz } from 'src/app/data/models/SimpleQuiz';
import { InputTextareaComponent } from 'src/app/features/input/input-textarea/input-textarea.component';
import { ModalConfirmComponent } from 'src/app/features/shared/modal/modal-confirm/modal-confirm.component';
import { ModalSubExpConfiguratorComponent } from 'src/app/features/shared/modal/modal-sub-exp-configurator/modal-sub-exp-configurator.component';
import { v4 as uuidv4 } from 'uuid';
import { ConfiguratorWithList } from '../../models/configurator-with-list';
import { ModalAddCategoryComponent } from 'src/app/features/shared/modal/modal-add-category/modal-add-category.component';

export enum CodeHuntCodeStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export class CodeHuntCodeUI {
    code: string;
    hint: string;
    location: string;
    subExpConfig: any[];
    status: CodeHuntCodeStatus;
    category?: string;

    get quizLength() {
        return this.subExpConfig?.length ?? 0;
    }
}

export interface HistoryContentEditor {
    config: any;
    metadata: HistoryMetaData;
}

export interface HistoryMetaData {
    code?: string;
    inputName?: string;
    key?: string;

    categories?: string[];
}


@Component({
    selector: 'app-codehunt-content-editor',
    templateUrl: './codehunt-content-editor.component.html',
    styleUrls: ['./codehunt-content-editor.component.scss']
})
export class CodehuntContentEditorComponent implements OnInit, OnDestroy {
    @ViewChild('codeHuntQuizContainer') codeHuntQuizContainer: ElementRef<HTMLDivElement>;


    @Input() orderId: number = null;
    @Input() config: any = null;
    @Input() codeHuntConfig: any = null;
    @Input() configByLang: any[] = [];
    @Input() structureConfig: any = null;

    @Input() changesSubject: BehaviorSubject<boolean> = null;

    @Input() undoRedoSubject: Subject<string> = null;
    undoRedoSubjectSubscription: Subscription = null;

    @Output() autoSaving: EventEmitter<{state: boolean; key?: 'structure' | 'content'; config?: any}> = new EventEmitter<{state: boolean; key?: 'structure' | 'content'; config?: any}>();

    @Output() undoStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() redoStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

    editorMode: boolean = false;

    selectedCode: CodeHuntCodeUI = null;
    selectedCodeIdx: number = 0;

    codesList: CodeHuntCodeUI[] = [];
    baseCodes: CodeHuntCode[] = [];
    maxCodes: number = 20;

    codeHuntCodeStatusEnum: typeof CodeHuntCodeStatus = CodeHuntCodeStatus;

    contentEditorFormGroup: FormGroup = null;
    editorValueChangesSubscription: Subscription = null;
    editorValueHintChangesDebounceTimeSubscription: Subscription = null;
    editorValueLocationChangesDebounceTimeSubscription: Subscription = null;

    isFormSubmitted: boolean = false;
    isUpdating: boolean = false;

    animatingQuestions: boolean = false;

    selectedLanguage: string = 'fr';

    statusUpdated: boolean = false;

    languages: {name: string; key: string}[] = [
        {
            name: 'Français',
            key: 'fr'
        }
    ];

    get currentCodesLength() {
        return this.codesList?.length ?? 0;
    }

    get selectedIdx() {
        if (!this.selectedCode) return 0;

        return this.codesList.findIndex(c => c.code === this.selectedCode.code);
    }

    get currentMultiCategories() {
        return this.codeHuntConfig?.multiCategories ?? [];
    }

    get hasMultiCategories() {
        return this.codeHuntConfig?.hasMultiCategories;
    }

    noHistoryTemp: boolean = false;

    configHistoryStack: HistoryContentEditor[] = [];
    redoHistoryStack: HistoryContentEditor[] = [];

    saveStatus: boolean[] = [];

    @HostListener('window:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if ((event.metaKey || event.ctrlKey) && (event.key === 'z' || event.key === 'Z') && !event.shiftKey) {
            event.preventDefault();
            this.onUndoClick();
        }

        if ((event.metaKey || event.ctrlKey) && event.shiftKey && (event.key === 'z' || event.key === 'Z')) {
            event.preventDefault();
            this.onRedoClick();
        }
    }

    constructor(
        private formBuilder: FormBuilder,
        private orderService: OrderService,
        private modalController: ModalController,
        private messageService: MessageService
    ) {

    }

    ngOnInit() {
        this.baseCodes = this.codeHuntConfig.baseCodes;

        this.initList();

        if (this.undoRedoSubject) {
            this.undoRedoSubjectSubscription = this.undoRedoSubject.subscribe((event: string) => {
                if (event === 'undo') {
                    this.onUndoClick();
                } else if (event === 'redo') {
                    this.onRedoClick();
                }
            });
        }
    }

    ngOnDestroy() {
        if (this.undoRedoSubjectSubscription) {
            this.undoRedoSubjectSubscription.unsubscribe();
        }
    }

    onAddElementClick() {
        this.addNewElement();
    }

    initList() {
        const codes = this.codeHuntConfig.codes;

        codes?.forEach((code) => {
            if (!this.codesList.some(c => c?.code === code?.code)) {
                this.codesList.push({
                    code: code.code,
                    hint: code?.hint?.content,
                    location: code?.hint?.location,
                    subExpConfig: code?.subExpConfig && code?.subExpConfig?.length > 0 ? code?.subExpConfig?.map(q => q?.SimpleQuiz) : null,
                    status: code?.status,
                    quizLength: code?.subExpConfig?.length ?? 0,
                    category: code?.category
                });
            }
        });

        this.maxCodes = this.codeHuntConfig.totalCodes ?? 20;


        // TODO REMOVE THIS
        // this.onEditClick(this.codesList[0]);

        // setTimeout(() => {
        //     this.onAddEditSubExpQuestionClick(1);
        // }, 500);
    }

    registerNewHistoryEvent(config: any, metadata: any, noSave: boolean = false) {
        this.configHistoryStack.push({ config, metadata });
        this.redoHistoryStack = [];
        this.undoStatus.emit(true);
        this.redoStatus.emit(false);

        if (!noSave) {
            this.saveContent(true);
        }
    }

    addNewElement() {
        let added = false;

        const codesListTmp = JSON.parse(JSON.stringify(this.codesList));

        this.baseCodes?.forEach((code) => {
            if (!added && !this.codesList.some(c => c?.code === code?.code)) {
                this.codesList.push({
                    code: code.code ?? null,
                    hint: code?.hint?.content ?? null,
                    location: code?.hint?.location ?? null,
                    subExpConfig: null,
                    quizLength: 0,
                    status: CodeHuntCodeStatus.ACTIVE,
                });
                added = true;
            }
        });

        if (added) {
            this.registerNewHistoryEvent(codesListTmp, { key: 'add-element' }, true);
            this.redoHistoryStack = [];
            this.onEditClick(this.codesList[this.codesList.length - 1]);
        }
    }

    onStatusChanged(status: CodeHuntCodeStatus, code: CodeHuntCodeUI) {
        if (!status || !code) return;

        const idx = this.codesList.findIndex(c => c.code === code.code);
        if (idx >= 0) {
            this.codesList[idx].status = status;
            this.statusUpdated = true;
            this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.codesList)), { code: code.code });
        }
    }

    editClickCount: number = 0;

    onEditClick(code: CodeHuntCodeUI, inputToFocus: 'hint' | 'location' = null, forceNoSaveStatus: boolean = false) {
        if (!code) return;

        // on active le mode d'update pour afficher le blur sur le contenu
        if (this.editorMode && this.selectedCode !== code) this.isUpdating = true;

        // on active le mode d'édition pour afficher le contenu et non la vue d'ensemble
        this.editorMode = true;

        // système pour savoir si on est déjà en train d'afficher le blur
        this.editClickCount++;
        const editTmp = JSON.parse(JSON.stringify(this.editClickCount));

        // s'il y a eu des changements, on les save avant de changer de code (en local, par encore en db)
        if (this.changesSubject.value === true) {
            this.saveContentLocal();
        }

        this.selectedCodeIdx = this.codesList.findIndex(c => c.code === code.code);

        if (this.selectedCode && this.selectedCode.code !== code.code && !forceNoSaveStatus) {
            this.saveStatus[this.selectedCode?.code] = true;
        }


        // scroll au top de l'élement id codehuntContentMainContainer
        const element = document.getElementById('codehuntContentMainContainer');
        if (element) {
            element.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }

        // on attends 200ms pour attendre l'animation de blur avant de charger le contenu
        setTimeout(() => {
            // on change le code sélectionné
            this.selectedCode = code;

            // initialisation du formGroup
            if (!this.contentEditorFormGroup) this.initFormGroup(code);
            else this.updateFormGroup(code);
        }, 200);

        // on désactive le mode d'update après 400ms si on n'a pas cliqué sur un autre code
        setTimeout(() => {
            if (this.editClickCount === editTmp) {
                this.isUpdating = false;

                if (inputToFocus) {
                    if (inputToFocus === 'hint') {
                        const inputElement = document.getElementsByClassName('mInput_textarea_container')[0];
                        if (inputElement && inputElement?.children?.length > 0) {
                            (inputElement.children[0] as any).focus();
                        }
                    } else {
                        const inputElement = document.getElementById('input-' + inputToFocus);
                        inputElement.focus();
                    }
                }
            }
        }, 400);
    }

    initFormGroup(code: CodeHuntCodeUI = null) {
        // on charge le contenu du code sélectionné
        this.contentEditorFormGroup = this.formBuilder.group({
            hint: [code?.hint ?? '', [Validators.required, Validators.maxLength(300)]],
            location: [code?.location ?? '', [Validators.maxLength(100)]],
        });

        if (this.hasMultiCategories) {
            this.contentEditorFormGroup.addControl('category', this.formBuilder.control(code?.category ?? null));
        }

        // on désactive l'ancien système de détection de changements
        if (this.editorValueChangesSubscription) this.editorValueChangesSubscription.unsubscribe();

        // on active le système de détection de changements
        this.editorValueChangesSubscription = this.contentEditorFormGroup.valueChanges.subscribe(() => {
            this.changesSubject.next(true);
        });

        this.editorValueHintChangesDebounceTimeSubscription = this.contentEditorFormGroup.get('hint')?.valueChanges.pipe(debounceTime(500)).subscribe((event: any) => {
            if (this.noHistoryTemp) return;
            this.onValueChange('hint');
        });

        this.editorValueLocationChangesDebounceTimeSubscription = this.contentEditorFormGroup.get('location')?.valueChanges.pipe(debounceTime(500)).subscribe((event: any) => {
            if (this.noHistoryTemp) return;
            this.onValueChange('location');
        });
    }

    updateFormGroup(code: CodeHuntCodeUI = null) {
        if (!code) return;

        this.noHistoryTemp = true;
        this.contentEditorFormGroup.get('hint').setValue(code?.hint ?? null);
        this.contentEditorFormGroup.get('location').setValue(code?.location ?? null);

        // add category to formgroup
        if (this.hasMultiCategories) {
            this.contentEditorFormGroup.get('category').setValue(code?.category ?? null);
        }

        setTimeout(() => {
            this.noHistoryTemp = false;
        }, 501);
    }

    onValueChange(inputName: string) {
        this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.codesList)), { code: this.selectedCode?.code, inputName });
    }

    saveContentLocal() {
        if (!this.selectedCode || this.selectedCode.status === CodeHuntCodeStatus.INACTIVE) return;

        const idx = this.codesList.findIndex(c => c.code === this.selectedCode.code);
        if (idx >= 0 && this.codesList[idx]) {
            this.codesList[idx].hint = this.contentEditorFormGroup.get('hint').value;
            this.codesList[idx].location = this.contentEditorFormGroup.get('location').value;
            if (this.hasMultiCategories) {
                this.codesList[idx].category = this.contentEditorFormGroup.get('category').value;
            }
        }
    }

    async saveContent(isAutoSave: boolean = false, force: boolean = false) {
        this.isFormSubmitted = true;

        if (!this.statusUpdated && this.contentEditorFormGroup.invalid && !force) {
            return;
        }
        if (isAutoSave) {
            this.autoSaving.emit({state: true});
        }

        this.saveContentLocal();

        const modularRoot = { ModularRoot: this.config?.modularConfig };

        const codehuntConfigFinal = this.codeHuntConfig;

        const finalCodes = this.codesList?.map((code) => ({
            code: code?.code,
            hint: {
                content: code?.hint ?? null,
                location: code?.hint ? code?.location : null,
            },
            status: code?.status,
            subExpConfig: code?.subExpConfig && code?.subExpConfig?.length > 0 ? code?.subExpConfig?.map(q => ({
                SimpleQuiz: q
            })) : null,
            quizLength: code?.subExpConfig?.length ?? 0,
            category: code?.category,
            points: this.structureConfig?.codesPoints ?? 50
        })
        ) ?? [];

        codehuntConfigFinal.codes = finalCodes;

        if (modularRoot.ModularRoot?.content[modularRoot?.ModularRoot?.content?.length - 1]?.CodeHunt) {
            modularRoot.ModularRoot.content[modularRoot?.ModularRoot?.content?.length - 1].CodeHunt = codehuntConfigFinal;
        }


        try {
            const res = await firstValueFrom(this.orderService.configureGenericExperience(this.orderId, modularRoot));

            this.statusUpdated = false;

            let hasAtLeastOneError = false;
            this.codesList?.forEach((code) => {
                if (this.saveStatus[code?.code] === true && this.hasErrorCode(code)) {
                    hasAtLeastOneError = true;
                }
            });

            if (!hasAtLeastOneError) {
                this.changesSubject.next(false);
            }
            if (isAutoSave) {
                setTimeout(() => {
                    this.autoSaving.emit({state: false, key: 'content', config: this.codesList});
                }, 1000);
            }
        } catch (error) {
            if (isAutoSave) {
                this.autoSaving.emit(null);
            }
            console.log('error while saving content of codehunt configurator', error);
        }
    }

    async onAddEditSubExpQuestionClick(questionIdx: number = null) {
        let config = null;
        if (questionIdx === null) {
            config = [new SimpleQuiz()];
            config[0].index = this.selectedCode?.subExpConfig?.length ?? 0;
        } else {
            config = JSON.parse(JSON.stringify(this.selectedCode?.subExpConfig));
        }

        const props: any = {
            config,
            isAdd: questionIdx === null,
            idx: questionIdx ?? 0,
            selectedLanguage: this.selectedLanguage,
        };

        const modal = await this.modalController.create({
            component: ModalSubExpConfiguratorComponent,
            componentProps: props,
            cssClass: 'modal-sub-exp-configurator',
            backdropDismiss: false
        });

        modal.onWillDismiss().then((dataReturned: any) => {
            if (dataReturned !== null && dataReturned.role === 'confirm') {
                this.saveContentLocal();
                this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.codesList)), { code: this.selectedCode.code }, true);

                if (dataReturned.data?.config?.length > 0) {

                    if (questionIdx === null) {
                        if (!this.selectedCode.subExpConfig) {
                            this.selectedCode.subExpConfig = [];
                        }

                        if (dataReturned?.data?.config?.length > 1) {
                            dataReturned.data.config.forEach((newConfig, idx) => {
                                this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.codesList)), { code: this.selectedCode.code }, true);
                                this.selectedCode.subExpConfig.push(newConfig);
                            });
                        } else {
                            this.selectedCode.subExpConfig = [...this.selectedCode.subExpConfig, ...dataReturned.data.config];
                        }
                    } else {
                        this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.codesList)), { code: this.selectedCode.code }, true);

                        this.selectedCode.subExpConfig = [...dataReturned.data.config];
                    }

                    this.saveContent(true, true);
                }
            }
        });
        return await modal.present();
    }

    onMoveSubExpQuestionTopClick(questionIdx: number) {
        if (questionIdx <= 0 || questionIdx >= this.selectedCode?.subExpConfig?.length || this.animatingQuestions) return;

        const configAtStart = JSON.parse(JSON.stringify(this.codesList));

        this.animatingQuestions = true;

        const elements: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName('configurator-quiz-item') as any;

        // get idx element height;
        const elementsToMoveTop = elements[questionIdx];
        const elementToMoveTopHeight = elementsToMoveTop.clientHeight;

        // get idx-1 element height;
        const elementsToMoveBottom = elements[questionIdx - 1];
        const elementToMoveBottomHeight = elementsToMoveBottom.clientHeight;

        elementsToMoveTop.style.transition = `transform 0.4s ease-in-out`;
        elementsToMoveTop.style.zIndex = '10';
        elementsToMoveTop.style.transform = `translateY(-${elementToMoveBottomHeight}px)`;

        elementsToMoveBottom.style.transition = `transform 0.4s ease-in-out`;
        elementsToMoveBottom.style.transform = `translateY(${elementToMoveTopHeight}px)`;


        setTimeout(() => {
            elementsToMoveTop.style.transform = `translateY(0px)`;
            elementsToMoveTop.style.transition = `none`;
            elementsToMoveBottom.style.transform = `translateY(0px)`;
            elementsToMoveBottom.style.transition = `none`;

            const tmp = this.selectedCode?.subExpConfig[questionIdx];
            this.selectedCode.subExpConfig[questionIdx] = this.selectedCode?.subExpConfig[questionIdx - 1];
            this.selectedCode.subExpConfig[questionIdx - 1] = tmp;

            this.registerNewHistoryEvent(configAtStart, { code: this.selectedCode.code });

            elementsToMoveTop.style.zIndex = null;
            this.animatingQuestions = false;
        }, 400);
    }

    onMoveSubExpQuestionBottomClick(questionIdx: number) {
        if (this.selectedCode?.subExpConfig?.length <= 0 || questionIdx >= this.selectedCode?.subExpConfig?.length - 1 || this.animatingQuestions) return;

        const configAtStart = JSON.parse(JSON.stringify(this.codesList));

        this.animatingQuestions = true;

        const elements: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName('configurator-quiz-item') as any;

        // get idx element height;
        const elementsToMoveBottom = elements[questionIdx];
        const elementToMoveBottomHeight = elementsToMoveBottom.clientHeight;

        // get idx+1 element height;
        const elementsToMoveTop = elements[questionIdx + 1];
        const elementToMoveTopHeight = elementsToMoveTop.clientHeight;

        elementsToMoveTop.style.transition = `transform 0.4s ease-in-out`;
        elementsToMoveTop.style.transform = `translateY(-${elementToMoveBottomHeight}px)`;

        elementsToMoveBottom.style.transition = `transform 0.4s ease-in-out`;
        elementsToMoveBottom.style.transform = `translateY(${elementToMoveTopHeight}px)`;
        elementsToMoveBottom.style.zIndex = '10';

        setTimeout(() => {
            elementsToMoveTop.style.transform = `translateY(0px)`;
            elementsToMoveTop.style.transition = `none`;
            elementsToMoveBottom.style.transform = `translateY(0px)`;
            elementsToMoveBottom.style.transition = `none`;

            const tmp = this.selectedCode?.subExpConfig[questionIdx];
            this.selectedCode.subExpConfig[questionIdx] = this.selectedCode?.subExpConfig[questionIdx + 1];
            this.selectedCode.subExpConfig[questionIdx + 1] = tmp;

            this.registerNewHistoryEvent(configAtStart, { code: this.selectedCode.code });

            elementsToMoveBottom.style.zIndex = null;
            this.animatingQuestions = false;
        }, 400);
    }

    async onDeleteSubExpQuestionClick(questionIdx: number) {
        if (!this.selectedCode || !this.selectedCode.subExpConfig || questionIdx < 0 || questionIdx >= this.selectedCode.subExpConfig.length) return;

        const question = this.selectedCode.subExpConfig[questionIdx].question;

        if (question?.length > 100) {
            // splice question to 100 char max and add '...' at the end
            this.selectedCode.subExpConfig[questionIdx].question = question.slice(0, 100) + '...';
        }

        const props: any = {
            paramTitle: 'Voulez-vous vraiment supprimer cette question ?',
            paramDescription: '\'' + this.selectedCode.subExpConfig[questionIdx].question + '\'',
        };

        const modal = await this.modalController.create({
            component: ModalConfirmComponent,
            componentProps: props,
            cssClass: 'modal-confirm-custom',
        });

        modal.onWillDismiss().then((dataReturned: any) => {
            if (dataReturned !== null && dataReturned.data === 'confirm') {
                // delete question from list
                this.selectedCode.subExpConfig.splice(questionIdx, 1);

                this.changesSubject.next(true);

                this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.codesList)), { code: this.selectedCode.code });

            }
        });
        return await modal.present();
    }



    waitingTiming = 400;

    onUndoClick() {
        if (this.configHistoryStack.length <= 0) return;

        // on active le mode changement d'historique pour ne pas trigger les onValueChanges des input
        this.noHistoryTemp = true;

        // on garde la config actuelle
        const currentConfig = JSON.parse(JSON.stringify(this.codesList));

        // on récupère la dernière config de l'historique
        const historyConfig = this.configHistoryStack.pop();

        // s'il y a des métadonnées, on les garde
        const metaData: any = { ...historyConfig?.metadata };
        if (historyConfig.metadata?.code) metaData.code = historyConfig.metadata.code;

        const metaDataRedo = JSON.parse(JSON.stringify(metaData));
        if (metaData?.key === 'multi-categories' && historyConfig.metadata?.categories?.length > 0) {
            metaDataRedo.categories = JSON.parse(JSON.stringify(this.codeHuntConfig.multiCategories));
        }

        // on ajoute la config actuelle à l'historique de redo
        this.redoHistoryStack.push({ config: currentConfig, metadata: metaDataRedo });

        this.undoStatus.emit(this.configHistoryStack.length > 0);
        this.redoStatus.emit(this.redoHistoryStack.length > 0);


        let timeout = 0;


        if (metaData?.key === 'add-element') {
            if (this.codesList?.length <= 1) {
                this.editorMode = false;
            } else {
                // go to the last element of the list
                this.saveStatus[this.selectedCode?.code] = false;
                this.onEditClick(this.codesList[this.codesList.length - 2], null, true);
                timeout = 200;
            }
        }

        // si on a des métadonnées de code, on change le code sélectionné
        if (historyConfig?.metadata?.code && historyConfig.metadata?.code !== this.selectedCode.code) {
            this.onEditClick(this.codesList.find(c => c.code === historyConfig.metadata?.code));
            timeout = this.waitingTiming;
        }


        // on attends soit 0 soit 400ms pour changer la config (durée de l'animation de navigation entre les codes)
        setTimeout(() => {
            // on change la config actuelle par la config de l'historique
            this.codesList = historyConfig.config;

            // on update le code sélectionné
            this.selectedCode = this.codesList.find(c => c.code === this.selectedCode.code);

            // si on a des métadonnées de code, on initialise le formGroup
            if (historyConfig?.metadata?.code) {
                const code = this.codesList.find(c => c.code === historyConfig.metadata?.code);

                if (this.selectedCode.code === code.code) {
                    this.initFormGroup(code);
                }
            }

            if (metaData?.key === 'multi-categories' && historyConfig.metadata?.categories?.length > 0) {
                this.codeHuntConfig.multiCategories = historyConfig.metadata.categories;
            }

            this.saveContent(true, true);

            // si on a des métadonnées d'input, on focus sur l'input
            if (historyConfig?.metadata?.inputName) {
                if (historyConfig.metadata.inputName === 'hint') {
                    const inputElement = document.getElementsByClassName('mInput_textarea_container')[0];
                    if (inputElement && inputElement?.children?.length > 0) {
                        (inputElement.children[0] as any).focus();
                    }
                } else {
                    const inputElement = document.getElementById('input-' + historyConfig.metadata.inputName);
                    inputElement.focus();
                }
            }
            // si on a pas de métadonnées d'input, on enlève le focus pour éviter de focus sur un input
            else {
                const inputElementHint = document.getElementsByClassName('mInput_textarea_container')[0];
                if (inputElementHint && inputElementHint?.children?.length > 0) {
                    (inputElementHint.children[0] as any).blur();
                }

                const inputElementLocation = document.getElementById('input-location');
                inputElementLocation.blur();
            }

            // on enlève le mode changement d'historique après 600ms
            setTimeout(() => {
                this.noHistoryTemp = false;
            }, 600);
        }, timeout);
    }

    onRedoClick() {
        if (this.redoHistoryStack.length <= 0) return;

        // on active le mode changement d'historique pour ne pas trigger les onValueChanges des input
        this.noHistoryTemp = true;

        // on garde la config actuelle
        const currentConfig = JSON.parse(JSON.stringify(this.codesList));

        // on récupère la dernière config de l'historique
        const historyConfig = this.redoHistoryStack.pop();

        let timeout = 0;

        // si on a des métadonnées de code, on change le code sélectionné
        if (historyConfig?.metadata?.code && historyConfig.metadata?.code !== this.selectedCode.code) {
            this.onEditClick(this.codesList.find(c => c.code === historyConfig.metadata?.code));
            timeout = this.waitingTiming;
        }

        const metaDataUndo = JSON.parse(JSON.stringify(historyConfig.metadata));
        if (metaDataUndo?.key === 'multi-categories' && historyConfig.metadata?.categories?.length > 0) {
            metaDataUndo.categories = JSON.parse(JSON.stringify(this.codeHuntConfig.multiCategories));
        }

        // on ajoute la config actuelle à l'historique de undo
        this.configHistoryStack.push({ config: currentConfig, metadata: metaDataUndo });
        // this.configHistoryStack.push({ config: currentConfig, metadata: {...historyConfig?.metadata} });

        this.undoStatus.emit(this.configHistoryStack.length > 0);
        this.redoStatus.emit(this.redoHistoryStack.length > 0);

        // on attends soit 0 soit waitingTime pour changer la config (durée de l'animation de navigation entre les codes)
        setTimeout(() => {
            // on change la config actuelle par la config de l'historique
            this.codesList = historyConfig.config;
            // on update le code sélectionné
            this.selectedCode = this.codesList.find(c => c.code === this.selectedCode?.code);

            const code = this.codesList.find(c => c.code === historyConfig.metadata?.code);

            // si on a des métadonnées de code, on initialise le formGroup
            if (historyConfig.metadata?.code) {
                if (this.selectedCode.code === code.code) {
                    this.initFormGroup(code);
                }
            }

            if (historyConfig?.metadata?.key === 'multi-categories' && historyConfig.metadata?.categories?.length > 0) {
                this.codeHuntConfig.multiCategories = historyConfig.metadata.categories;
            }

            this.saveContent(true, true);

            // si on a des métadonnées d'input, on focus sur l'input
            if (historyConfig?.metadata?.inputName) {
                if (historyConfig.metadata.inputName === 'hint') {
                    const inputElement = document.getElementsByClassName('mInput_textarea_container')[0];
                    if (inputElement && inputElement?.children?.length > 0) {
                        (inputElement.children[0] as any).focus();
                    }
                } else {
                    const inputElement = document.getElementById('input-' + historyConfig.metadata.inputName);
                    inputElement.focus();
                }
            }
            // si on a pas de métadonnées d'input, on enlève le focus pour éviter de focus sur un input
            else {
                // remove focus, to avoid focus on input
                const inputElementHint = document.getElementsByClassName('mInput_textarea_container')[0];
                if (inputElementHint && inputElementHint?.children?.length > 0) {
                    (inputElementHint.children[0] as any).blur();
                }

                const inputElementLocation = document.getElementById('input-location');
                inputElementLocation.blur();
            }

            // on enlève le mode changement d'historique après 600ms
            setTimeout(() => {
                this.noHistoryTemp = false;
            }, 600);
        }, timeout);
    }

    hasErrorCode(code: CodeHuntCodeUI) {
        if (!code) return false;

        if (code?.status === CodeHuntCodeStatus.INACTIVE) return false;

        return (!code?.subExpConfig || code?.subExpConfig?.length <= 0) && (!code?.hint || code?.hint?.length <= 0);
    }

    async onDeleteCodeClick() {
        const props: any = {
            paramTitle: 'Voulez-vous vraiment supprimer ce QR Code ?',
            paramDescription: '\"' + this.selectedCode.code + '\"',
        };

        const modal = await this.modalController.create({
            component: ModalConfirmComponent,
            componentProps: props,
            cssClass: 'modal-confirm-custom',
        });

        modal.onWillDismiss().then((dataReturned: any) => {
            if (dataReturned !== null && dataReturned.data === 'confirm') {
                // delete code from list
                this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.codesList)), { code: this.selectedCode.code }, true);

                this.codesList = this.codesList.filter(c => c.code !== this.selectedCode.code);

                this.saveContent(true, true);

                // selecte first code
                if (this.codesList.length > 0) {
                    this.onEditClick(this.codesList[0]);
                } else {
                    this.selectedCode = null;
                    this.editorMode = false;
                }
            }
        });
        return await modal.present();
    }

    async addCategory(isEdit: boolean = false) {
        const props: any = {
            categoryList: this.currentMultiCategories,
            languages: this.languages,
        };

        if (isEdit) {
            props.category = {fr: this.contentEditorFormGroup.get('category').value};
        }

        const modal = await this.modalController.create({
            component: ModalAddCategoryComponent,
            componentProps: props,
            backdropDismiss: false,
            cssClass: 'modal-add-category',
        });

        modal.onWillDismiss().then((dataReturned: any) => {
            if (dataReturned !== null && dataReturned?.data && dataReturned?.data[this.selectedLanguage]) {
                // on ajoute l'event dans l'historique
                this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.codesList)), { key: 'multi-categories', code: this.selectedCode?.code, categories: JSON.parse(JSON.stringify(this.currentMultiCategories)) }, true);

                if (!isEdit) {
                    // on ajoute la nouvelle catégorie
                    this.currentMultiCategories.push(dataReturned.data[this.selectedLanguage]);
                } else {
                    // on update la catégorie de codeHuntConfig.multiCategories
                    this.codeHuntConfig.multiCategories = this.currentMultiCategories.map(c => c === this.contentEditorFormGroup.get('category').value ? dataReturned.data[this.selectedLanguage] : c);
                }

                // on update le formGroup
                this.contentEditorFormGroup.get('category').setValue(dataReturned.data[this.selectedLanguage]);

                // on save le contenu
                this.saveContent(true, true);
            }
        });

        return await modal.present();
    }

    onCategoryChange(event: any) {
        this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.codesList)), { code: this.selectedCode.code }, true);
        this.saveContent(true, true);
    }

    editCategory() {
        this.addCategory(true);
    }

    async removeDefinitelyCategory() {
        const props: any = {
          paramTitle: 'Voulez-vous vraiment supprimer cette catégorie ?',
          paramDescription: '"' + this.contentEditorFormGroup.get('category').value + '"',
        };

        const modal = await this.modalController.create({
          component: ModalConfirmComponent,
          componentProps: props,
          cssClass: 'modal-confirm-custom',
        });

        modal.onWillDismiss().then(async (dataReturned: any) => {
            if (dataReturned?.data === 'confirm') {
                this.saveContentLocal();

                // on ajoute l'event dans l'historique
                this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.codesList)), { key: 'multi-categories', code: this.selectedCode?.code, categories: JSON.parse(JSON.stringify(this.currentMultiCategories)) }, true);

                // on supprime la catégorie de codeHuntConfig.multiCategories
                this.codeHuntConfig.multiCategories = this.currentMultiCategories.filter(c => c !== this.contentEditorFormGroup.get('category').value);

                // on update le formGroup
                this.contentEditorFormGroup.get('category').setValue(null);

                // on save le contenu
                this.saveContent(true, true);
            }
        });
        return await modal.present();
    }

}
