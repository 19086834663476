<div class="steps-main-container" [ngStyle]="{'justify-content': config?.horizontalAlign, 'align-items': config?.verticalAlign, 'width': config?.width}">
    <ng-container *ngFor="let step of config?.steps; let idx = index">
        
        <div class="steps-item" [ngClass]="{'steps-item-selected': idx === stepsService.currentStepIdx}" (click)="onStepClick(step, idx)">
            <div class="steps-top">
                <div class="ring" [ngClass]="{'ring-error': step?.hasError === true}" [ngStyle]="{'background-color': stepStatusColors[getStatus(idx)]}">{{step?.hasError ? '!' : (idx + 1)}}</div>
                <div class="bar" [ngStyle]="{'background-color': stepStatusColors[getStatus(idx)]}" *ngIf="(idx + 1) < config?.steps?.length" ></div>
            </div>
            
            <div class="steps-center"[ngStyle]="{'color': stepStatusColors[getStatus(idx)]}">
                {{step?.label}}
            </div>
            
            <div class="steps-bottom" style="height: 19px;">
                <div *ngIf="!step?.noBadge" [class]="'steps-badge steps-badge-' + getStatus(idx)" [ngStyle]="{'background-color': (stepStatusColors[getStatus(idx)] + '4d'), 'color': stepStatusColors[getStatus(idx)]}">{{stepStatusLabels[getStatus(idx)]}}</div>
            </div>
        </div>
    </ng-container>
</div>