<div class="mInput_text_label-container">
    <app-input-label [label]="label" [labelDescription]="labelDescription" [errors]="errors ?? inputFormGroup?.get(inputFormControlName)?.errors ?? null" [isFormSubmitted]="isFormSubmitted" [optional]="optional" [optionalFeminine]="optionalFeminine"></app-input-label>
</div>

<!-- Input enabled -->
<div [formGroup]="inputFormGroup" class="mInput_text_container" *ngIf="!disabled && inputFormGroup" [style]="('width: ' + forcedWidth + ' !important')">
    <input
        [id]="customId"
        type="text"
        class="input-text"
        [ngClass]="{
            'input-text-counter': maxLength > 0,
            'input-text-error': inputFormGroup?.get(inputFormControlName)?.invalid && isFormSubmitted
        }"

        [formControlName]="inputFormControlName"
        [placeholder]="placeholder"

        (input)="onInputChange($event.target)"
    >

    <div class="mInput_text_char-counter" *ngIf="maxLength > 0">
        <b [ngClass]="{'mInput_text_char-counter-error': valueLength > maxLength}">{{valueLength}}</b> / {{maxLength}}
    </div>
</div>


<!-- Input disabled -->
<div class="mInput_text_container" *ngIf="disabled" [style]="('width: ' + forcedWidth + ' !important')">
    <input 
        disabled
        [value]="value"
        class="input-text input-disabled-text"
    >
</div>