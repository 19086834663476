/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { environment } from 'src/environments/environment';
/* eslint-disable arrow-body-style */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StepsService } from 'src/app/features/steps/steps.service';
import { HeaderBarService } from 'src/app/services/HeaderBarService';
import { CodehuntStructureComponent } from './codehunt-structure/codehunt-structure.component';
import { BehaviorSubject, firstValueFrom, Subject, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { CodeHunt, CodeHuntCode } from 'src/app/data/models/CodeHunt';
import { ExperienceService, OrderService } from '@mocli/MocliCommonLib';
import { AuthService } from 'src/app/services/AuthService';
import { ExperienceOrder } from '@mocli/MocliCommonLib/lib/data/models/ExperienceOrder';
import { CodeHuntCodeStatus, CodehuntContentEditorComponent } from './codehunt-content-editor/codehunt-content-editor.component';
import { MessageService } from 'primeng/api';

export interface CodeHuntSticker {
    name: string;
    quantity: number;
    points: number;
    imageUrl?: string;
}

@Component({
    selector: 'app-code-hunt-configurator',
    templateUrl: './code-hunt-configurator.component.html',
    styleUrls: ['./code-hunt-configurator.component.scss'],
})
export class CodeHuntConfiguratorComponent implements OnInit, OnDestroy {
    @ViewChild(CodehuntStructureComponent) codehuntStructureComponent!: CodehuntStructureComponent;
    @ViewChild(CodehuntContentEditorComponent) codeHuntContentEditorComponent!: CodehuntContentEditorComponent;

    undoRedoSubject: Subject<string> = new Subject<string>();

    config: any;
    configByLang: any[] = [];
    codeHuntConfig: any = null;
    structureConfig: any = null;
    order: ExperienceOrder = null;

    saveSubject: Subject<{ ref: any; nextUrl: string }>;
    saveSubscription: Subscription = null;

    changesSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isCompleteSubject: any;

    orderId: number = null;

    isSaving: boolean = false;
    isAutoSaving: boolean = false;
    hasAutoSaved: boolean = false;
    isMultiQuiz: boolean = false;
    isCodeHuntQuiz: boolean = false;

    undoDisabled: boolean = true;
    redoDisabled: boolean = true;

    constructor(
        private headerBarService: HeaderBarService,
        private route: ActivatedRoute,
        private orderService: OrderService,
        private authService: AuthService,
        private experienceSrv: ExperienceService,
        private messageService: MessageService,

        public stepsService: StepsService
    ) { }


    /* ----------- Lifecycle ----------- */

    // INIT

    async ngOnInit(): Promise<void> {
        this.orderId = this.route.snapshot.params.id;

        if (this.config?.content[0]?.CodeHunt) this.codeHuntConfig = this.config?.content[0]?.CodeHunt;

        if (this.codeHuntConfig?.isQuizLeaderboard) this.isMultiQuiz = true;
        if (this.codeHuntConfig?.isCodeHuntQuiz) this.isCodeHuntQuiz = true;

        this.structureConfig = {
            gameName: this.config?.configuration?.title ?? '',
            introText: this.codeHuntConfig?.introText ?? '',
            codesPoints: this.codeHuntConfig?.codesPoints ?? 50
        };

        if (this.isCodeHuntQuiz) {
            this.structureConfig.subExpAnswerScore = this.codeHuntConfig?.subExpAnswerScore ?? 50;
        }

        if (!this.isMultiQuiz) {
            this.structureConfig.searchArea = this.codeHuntConfig?.searchArea ?? '';
            this.structureConfig.stickerWord = this.codeHuntConfig?.stickerWord ?? 'QR Code';
            this.structureConfig.stickerWordPlural = this.codeHuntConfig?.stickerWordPlural ?? 'QR Codes';
            this.structureConfig.demonstrativeAdjective = this.codeHuntConfig?.demonstrativeAdjective ?? 'ce';
        }

        this.setupHeaderBar();

        if (!this.codeHuntConfig?.totalCodes) {
            await this.getAdditionnalConfig();
        }


        let needSave = false;

        if (this.codeHuntConfig?.codes?.length > 0 && this.codeHuntConfig?.codes?.some(code => code?.status === undefined)) {
            this.codeHuntConfig.codes = this.codeHuntConfig.codes.map(code => {
                return {
                    ...code,
                    status: code?.status ?? CodeHuntCodeStatus.ACTIVE,
                };
            });
            needSave = true;
        }

        if (!this.codeHuntConfig?.baseCodes || this.codeHuntConfig.baseCodes?.length <= 0) {
            if (this.codeHuntConfig?.codes && this.codeHuntConfig?.codes?.length > 0) {
                this.codeHuntConfig.baseCodes = this.codeHuntConfig.codes;

                if (this.codeHuntConfig?.baseCodes?.length < this.codeHuntConfig?.totalCodes) {
                    this.codeHuntConfig.baseCodes = this.generateListOfCodes(this.codeHuntConfig.totalCodes - this.codeHuntConfig.baseCodes.length);
                }
            } else {
                this.codeHuntConfig.baseCodes = this.generateListOfCodes(this.codeHuntConfig.totalCodes);
            }
            needSave = true;
        }

        if (needSave) {
            this.saveConfig();
        }

        if (this.saveSubject) {
            this.saveSubscription = this.saveSubject.subscribe(event => {
                this.codeHuntContentEditorComponent.saveContent();
            });
        }

        const newCodeHunt = new CodeHunt({ isCodeHuntQuiz: this.isCodeHuntQuiz, isQuizLeaderboard: this.isMultiQuiz });

        if (this.config?.configuration?.title !== 'Chasse Modulaire Quizée' || this.codeHuntConfig?.introText !== newCodeHunt.introText || this.codeHuntConfig?.searchArea !== '') {
            this.stepsService.currentStepIdx = 1;
        }

        setInterval(() => {
            // console.log('this.undoDisabled', this.undoDisabled);
        }, 100);

        // TODO: enlever cette ligne
        // if (environment.production === false) {
        // this.stepsService.currentStepIdx = 1;
        // }
    }

    ionViewWillEnter() {
        this.setupHeaderBar();
    }


    // DESTROY

    ngOnDestroy() {
        this.stepsService.currentStepIdx = 0;
        this.resetHeaderBar();
        if (this.saveSubscription) this.saveSubscription.unsubscribe();
    }

    ionViewWillLeave() {
        this.stepsService.currentStepIdx = 0;
        this.resetHeaderBar();
    }


    /* ----------- Header Bar ----------- */

    resetHeaderBar() {
        this.headerBarService.onHeaderBarChanged.next({
            ...this.headerBarService.onHeaderBarChanged.value,
            stepsConfig: null
        });
    }

    setupHeaderBar() {
        this.headerBarService.onHeaderBarChanged.next({
            ...this.headerBarService.onHeaderBarChanged.value,
            stepsConfig: {
                steps: [
                    {
                        label: 'Structure',
                        checkClickable: this.isConfigClickable,
                    },
                    {
                        label: 'Édition de QR Codes',
                        clickable: false,
                        checkClickable: this.isConfigClickable,
                    }
                ],
                width: 'fit-content',
            }
        });
    }

    isConfigClickable = () => {
        this.undoDisabled = true;
        this.redoDisabled = true;
        return true;
    };



    /* ----------- Sauvegarde ----------- */


    async saveConfig() {
        const configFinal = this.config?.modularConfig;
        configFinal.content[configFinal?.content?.length - 1].CodeHunt = this.codeHuntConfig;

        try {
            await firstValueFrom(this.orderService.configureGenericExperience(this.orderId, { ModularRoot: configFinal }));
        } catch (error) {
            throw error;
        }
    }

    async onSaveClick() {
        if (this.changesSubject && this.changesSubject.value === false) return;

        this.isSaving = true;

        try {
            // save de la structure
            if (this.stepsService.currentStepIdx === 0 && this.codehuntStructureComponent) {
                const res = await this.codehuntStructureComponent.saveStructure();
                if (!res) {
                    this.isSaving = false;
                    return;
                }

                console.log('res structure', res);

            }
            // save de l'edition
            else if (this.stepsService.currentStepIdx === 1 && this.codeHuntContentEditorComponent) {
                const res = await this.codeHuntContentEditorComponent.saveContent();
            }

            // everything is saved
            if (this.changesSubject) this.changesSubject.next(false);
        } catch (err) {
            console.log('current step index', this.stepsService.currentStepIdx);
            console.log('on saveClick on codehunt-configurator', err);
        }
        this.isSaving = false;
    }

    /* ----------- Auto Save ----------- */

    autoSavingTriggered(event: { state: boolean; key?: string; config?: any }) {
        if (event?.state === true) {
            this.isAutoSaving = true;
        } else if (event?.state === false) {
            if (this.isAutoSaving === true) {
                setTimeout(() => this.hasAutoSaved = true, 300);
                setTimeout(() => this.hasAutoSaved = false, 2000);

                if (event?.config) {
                    if (event?.key === 'structure') {
                        this.structureConfig = event.config;
                    } else if (event?.key === 'content') {
                        this.codeHuntConfig.codes = event.config;
                    }
                }
            }
            this.isAutoSaving = false;
        } else if (event?.state === null) {
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Une erreur est survenue lors de la sauvegarde automatique.' });
        }

        // this.structureConfig =
    }

    /* ----------- Navigation ----------- */

    onPrevClick() {
        this.stepsService.previousStep();
    }
    onNextClick() {
        this.stepsService.nextStep();
    }


    /* ----------- Structure ----------- */

    generateListOfCodes(count: number): CodeHuntCode[] {
        const codes: Set<string> = new Set();
        const newCodes: CodeHuntCode[] = [];
        let maxOverlap = 0;

        if (count <= 0) return null;

        // Generate the specified number of unique codes
        while (codes.size < count && maxOverlap < 1000) {
            codes.add(this.generateRandomCode());
            maxOverlap++;
        }

        Array.from(codes)?.forEach(newCode => {
            newCodes.push({ code: newCode, points: this.codeHuntConfig?.codesPoints ?? 1, visible: true });
        });

        newCodes?.sort((a, b) => a.code.localeCompare(b.code));

        newCodes?.forEach((code, codeIdx) => {
            console.log(codeIdx, code.code);
        });

        // Convert the set to an array and return it
        return newCodes;
    }

    generateRandomCode(): string {
        const letters = 'ABCDEFGHJKMNPQRSTUVWXYZ';
        const digits = '0123456789';

        // Generate two random letters
        let letterPart = letters.charAt(Math.floor(Math.random() * letters.length)) +
            letters.charAt(Math.floor(Math.random() * letters.length));

        // Generate three random digits
        let digitPart = digits.charAt(Math.floor(Math.random() * digits.length)) +
            digits.charAt(Math.floor(Math.random() * digits.length)) +
            digits.charAt(Math.floor(Math.random() * digits.length));

        let maxOverlap = 0;
        while (maxOverlap < 100 && (letterPart === 'HH' || letterPart === 'SS' || letterPart === 'PD')) {
            letterPart = letters.charAt(Math.floor(Math.random() * letters.length)) +
                letters.charAt(Math.floor(Math.random() * letters.length));
            maxOverlap++;
        }

        maxOverlap = 0;
        while (maxOverlap < 100 && (digitPart === '666' || digitPart === '333')) {
            digitPart = digits.charAt(Math.floor(Math.random() * digits.length)) +
                digits.charAt(Math.floor(Math.random() * digits.length)) +
                digits.charAt(Math.floor(Math.random() * digits.length));
            maxOverlap++;
        }

        // Combine and return the code
        return letterPart + digitPart;
    }

    async getAdditionnalConfig() {
        try {
            const res: any = await firstValueFrom(this.orderService.getSubscribedOrderOptions(this.authService.userLogged.value?.id, this.orderId));

            if (res?.options?.stickerOptions[0]?.values?.count) {
                this.codeHuntConfig.totalCodes = res?.options?.stickerOptions[0]?.values?.count;
                await this.saveConfig();
            }
        } catch (error) {
            console.log('getAdditionnalConfig error', error);
        }
    }
}
