import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-input-label',
  templateUrl: './input-label.component.html',
  styleUrls: ['./input-label.component.scss']
})
export class InputLabelComponent {
  @Input() label: string = null;
  @Input() errors: any = null;
  @Input() isFormSubmitted: boolean = null;
  @Input() optional: boolean = false;
  @Input() optionalFeminine: boolean = false;
  @Input() labelDescription: string = null;

  getErrorsFmt() {
    if (!this.errors) return null;

    if (this.errors?.required === true) return 'Champ requis';

    if (this.errors?.maxlength) return 'Taille maximum atteinte';

    return 'Erreur inconnue';
  }
}
