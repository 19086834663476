/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-len */
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { OrderService } from '@mocli/MocliCommonLib';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, debounceTime, firstValueFrom, Subject, Subscription } from 'rxjs';
import { ModalEditObjectNameComponent } from 'src/app/features/shared/modal/modal-edit-object-name/modal-edit-object-name.component';
import { HistoryContentEditor } from '../codehunt-content-editor/codehunt-content-editor.component';

@Component({
    selector: 'app-codehunt-structure',
    templateUrl: './codehunt-structure.component.html',
    styleUrls: ['./codehunt-structure.component.scss']
})
export class CodehuntStructureComponent implements OnInit {
    @Input() orderId: number = null;
    @Input() config: any = null;
    @Input() configByLang: any[] = [];

    @Input() changesSubject: BehaviorSubject<boolean> = null;
    @Output() autoSaving: EventEmitter<{state: boolean; key?: 'structure' | 'content'; config?: any}> = new EventEmitter<{state: boolean; key?: 'structure' | 'content'; config?: any}>();

    @Input() undoRedoSubject: Subject<string> = null;
    undoRedoSubjectSubscription: Subscription = null;

    languages: any[] = [];
    langConfigToSaveLater: string[] = [];

    codesPoints: number = 50;
    subExpQuestionPoints: number = 50;

    codeHuntStructureForm: FormGroup = null;
    isFormSubmitted: boolean = false;

    isMultiQuiz: boolean = false;
    isCodeHuntQuiz: boolean = false;

    codeHuntConfig: any = null;
    structureConfig: any = null;

    configHistoryStack: HistoryContentEditor[] = [];
    redoHistoryStack: HistoryContentEditor[] = [];
    @Output() undoStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() redoStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

    codePointsList: number[] = [ 10, 25, 50, 75, 100, 150, 200 ];
    bonusPointsList: number[] = [ 10, 25, 50, 75, 100, 150, 200 ];

    get totalCodes() {
        return this.codeHuntConfig?.totalCodes ?? 20;
    }

    get inputDisabled() {
        return false;
    }

    get isValid() {
        return this.codeHuntStructureForm?.valid;
    }

    get objectName() {
        return this.codeHuntConfig.stickerWord ?? 'QR Code';
    }

    get objectNamePlural() {
        return this.codeHuntConfig.stickerWordPlural ?? 'QR Codes';
    }

    get demonstrativeAdjective() {
        return this.codeHuntConfig.demonstrativeAdjective ?? 'ce';
    }

    @HostListener('window:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if ((event.metaKey || event.ctrlKey) && (event.key === 'z' || event.key === 'Z') && !event.shiftKey) {
            event.preventDefault();
            this.onUndoClick();
        }

        if ((event.metaKey || event.ctrlKey) && event.shiftKey && (event.key === 'z' || event.key === 'Z')) {
            event.preventDefault();
            this.onRedoClick();
        }
    }

    constructor(
        private fb: FormBuilder,
        private orderService: OrderService,
        private messageService: MessageService,
        private modalController: ModalController
    ) {}

    ngOnInit() {
        if (this.config?.content[0]?.CodeHunt) this.codeHuntConfig = this.config?.content[0]?.CodeHunt;

        if (this.codeHuntConfig?.isQuizLeaderboard) this.isMultiQuiz = true;
        if (this.codeHuntConfig?.isCodeHuntQuiz) this.isCodeHuntQuiz = true;

        this.initFormGroup();

        // this.onEditObjectNameClick();

        if (this.undoRedoSubject) {
            this.undoRedoSubjectSubscription = this.undoRedoSubject.subscribe((event: string) => {
                if (event === 'undo') {
                    this.onUndoClick();
                } else if (event === 'redo') {
                    this.onRedoClick();
                }
            });
        }
    }

    initFormGroup() {
        this.codeHuntStructureForm = this.fb.group({
            gameName: [this.config?.configuration?.title ?? '', [Validators.required, Validators.maxLength(30)]],
            introText: [this.codeHuntConfig?.introText ?? '', [Validators.required, Validators.maxLength(500)]],
        });

        this.structureConfig = {
            gameName: this.config?.configuration?.title ?? '',
            introText: this.codeHuntConfig?.introText ?? '',
            codesPoints: this.codeHuntConfig?.codesPoints ?? 50
        };

        if (this.isCodeHuntQuiz) {
            this.structureConfig.subExpAnswerScore = this.codeHuntConfig?.subExpAnswerScore ?? 50;
        }

        if (!this.isMultiQuiz) {
            this.codeHuntStructureForm.addControl('searchArea', this.fb.control(this.codeHuntConfig?.searchArea ?? '', [Validators.maxLength(100)]));
            this.structureConfig.searchArea = this.codeHuntConfig?.searchArea ?? '';
            this.structureConfig.stickerWord = this.codeHuntConfig?.stickerWord ?? 'QR Code';
            this.structureConfig.stickerWordPlural = this.codeHuntConfig?.stickerWordPlural ?? 'QR Codes';
            this.structureConfig.demonstrativeAdjective = this.codeHuntConfig?.demonstrativeAdjective ?? 'ce';
        }

        this.codesPoints = this.codeHuntConfig?.codesPoints ?? 50;
        this.subExpQuestionPoints = this.codeHuntConfig?.subExpAnswerScore ?? 50;

        this.codeHuntStructureForm.valueChanges.subscribe((event: any) => {
            if (this.changesSubject) this.changesSubject.next(true);
        });

        Object.keys(this.codeHuntStructureForm.controls).forEach(controlName => {
            this.codeHuntStructureForm.get(controlName)?.valueChanges.pipe(debounceTime(500)).subscribe(value => {
                if (this.updatingFormGroup) return;

                this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.structureConfig)), { inputName: controlName });
                this.structureConfig[controlName] = value;
            });
        });
    }

    registerNewHistoryEvent(config: any, metadata: any, noSave: boolean = false) {
        if (this.updatingFormGroup) return;

        this.configHistoryStack.push({ config, metadata });
        this.redoHistoryStack = [];
        this.undoStatus.emit(true);
        this.redoStatus.emit(false);

        if (!noSave) {
            this.saveStructure(true);
        }
    }

    updateCodesPoints() {
        if (this.changesSubject) this.changesSubject.next(true);

        this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.structureConfig)), {inputName: 'codesPoints'}, true);

        this.structureConfig.codesPoints = this.codesPoints;

        if (this.codeHuntConfig?.codes?.length > 0) {
            this.codeHuntConfig?.codes?.forEach(code => {
                if (code) code.points = this.codesPoints;
            });
        }

        this.saveStructure(true);
    }

    updateSubExpCodesPoints() {
        if (this.changesSubject) this.changesSubject.next(true);

        this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.structureConfig)), null, true);

        this.structureConfig.subExpAnswerScore = this.subExpQuestionPoints;

        this.saveStructure(true);
    }

    async saveStructure(isAutoSave: boolean = false) {
        this.isFormSubmitted = true;
        const configFinal = this.config?.modularConfig;

        if (this.codeHuntStructureForm.invalid || !configFinal.content[configFinal?.content?.length - 1].CodeHunt) {
            return false;
        }

        if (isAutoSave) {
            this.autoSaving.emit({state: true});
        }


        configFinal.configuration.title = this.codeHuntStructureForm.value.gameName;
        configFinal.content[configFinal?.content?.length - 1].CodeHunt.subExpAnswerScore = this.subExpQuestionPoints;
        configFinal.content[configFinal?.content?.length - 1].CodeHunt.introText = this.codeHuntStructureForm.value.introText;
        configFinal.content[configFinal?.content?.length - 1].CodeHunt.codesPoints = this.structureConfig.codesPoints;
        configFinal.content[configFinal?.content?.length - 1].CodeHunt.codes = this.codeHuntConfig?.codes ?? [];

        if (this.isCodeHuntQuiz) {
            configFinal.content[configFinal?.content?.length - 1].CodeHunt.subExpAnswerScore = this.structureConfig.subExpAnswerScore;
        }

        if (!this.isMultiQuiz) {
            configFinal.content[configFinal?.content?.length - 1].CodeHunt.searchArea = this.codeHuntStructureForm.value.searchArea;
            configFinal.content[configFinal?.content?.length - 1].CodeHunt.stickerWord = this.structureConfig.stickerWord;
            configFinal.content[configFinal?.content?.length - 1].CodeHunt.stickerWordPlural = this.structureConfig.stickerWordPlural;
            configFinal.content[configFinal?.content?.length - 1].CodeHunt.demonstrativeAdjective = this.structureConfig.demonstrativeAdjective;
        }

        try {
            await firstValueFrom(this.orderService.configureGenericExperience(this.orderId, { ModularRoot: configFinal }));

            this.isFormSubmitted = false;
            this.changesSubject.next(false);

            if (isAutoSave) {
                setTimeout(() => {
                    this.autoSaving.emit({state: false, key: 'structure', config: this.structureConfig});
                }, 1000);
            }
            return true;
        } catch (error) {
            // throw error so parent can catch it
            if (isAutoSave) {
                this.autoSaving.emit(null);
            }
            throw error;
        }
    }

    async onEditObjectNameClick() {
        const props = {
            objectName: this.objectName,
            objectNamePlural: this.objectNamePlural,
            demonstrativeAdjective: this.demonstrativeAdjective,
        };

        const modal = await this.modalController.create({
            component: ModalEditObjectNameComponent,
            componentProps: props,
            cssClass: 'modal-edit-object-name',
            backdropDismiss: false,
        });

        modal.onWillDismiss().then((dataReturned: any) => {
            if (dataReturned !== null && dataReturned.role === 'confirm') {
                this.registerNewHistoryEvent(JSON.parse(JSON.stringify(this.structureConfig)), null, true);

                this.codeHuntConfig.stickerWord = dataReturned.data.objectName;
                this.codeHuntConfig.stickerWordPlural = dataReturned.data.objectNamePlural;
                this.codeHuntConfig.demonstrativeAdjective = dataReturned.data.demonstrativeAdjective;

                this.structureConfig.stickerWord = dataReturned.data.objectName;
                this.structureConfig.stickerWordPlural = dataReturned.data.objectNamePlural;
                this.structureConfig.demonstrativeAdjective = dataReturned.data.demonstrativeAdjective;
                if (this.changesSubject) this.changesSubject.next(true);

                this.saveStructure(true);
            }
        });
        return await modal.present();
    }

    waitingTiming = 400;
    updatingFormGroup = false;
    undoClick = 0;

    onUndoClick() {
        if (this.configHistoryStack.length <= 0) return;

        this.updatingFormGroup = true;

        this.undoClick++;
        const undoClickTemp = JSON.parse(JSON.stringify(this.undoClick));


        // on garde la config actuelle
        const currentConfig = JSON.parse(JSON.stringify(this.structureConfig));

        // on récupère la dernière config de l'historique
        const historyConfig = this.configHistoryStack.pop();

        // s'il y a des métadonnées, on les garde
        const metaData: any = { ...historyConfig?.metadata };


        // on ajoute la config actuelle à l'historique de redo
        this.redoHistoryStack.push({ config: currentConfig, metadata: metaData });
        this.undoStatus.emit(this.configHistoryStack.length > 0);
        this.redoStatus.emit(this.redoHistoryStack.length > 0);

        // on change la config actuelle par la config de l'historique
        this.structureConfig = JSON.parse(JSON.stringify(historyConfig.config));

        this.codeHuntStructureForm.patchValue(this.structureConfig);

        // on met à jour les points de codes
        this.codesPoints = this.structureConfig.codesPoints;

        // on met à jour les points de codes dans les codes
        if (metaData?.inputName === 'codesPoints') {
            if (this.codeHuntConfig?.codes?.length > 0) {
                this.codeHuntConfig?.codes?.forEach(code => {
                    if (code) code.points = this.codesPoints;
                });
            }
        }

        // on met à jour les points de quiz
        this.subExpQuestionPoints = this.structureConfig.subExpAnswerScore;

        // si on a des métadonnées d'input, on focus sur l'input
        if (historyConfig?.metadata?.inputName) {
            if (historyConfig.metadata.inputName === 'introText') {
                const inputElement = document.getElementsByClassName('mInput_textarea_container')[0];
                if (inputElement && inputElement?.children?.length > 0) {
                    (inputElement.children[0] as any).focus();
                }
            } else if (historyConfig.metadata.inputName !== 'codesPoints') {
                const inputElement = document.getElementById('input-' + historyConfig.metadata.inputName);
                inputElement.focus();
            }
        }

        setTimeout(() => {
            if (this.undoClick === undoClickTemp) {
                this.updatingFormGroup = false;
                this.saveStructure(true);
            }
        }, 600);

    }

    redoClick = 0;

    onRedoClick() {
        if (this.redoHistoryStack.length <= 0) return;

        this.updatingFormGroup = true;

        this.redoClick++;
        const redoClickTemp = JSON.parse(JSON.stringify(this.redoClick));


        // on garde la config actuelle
        const currentConfig = JSON.parse(JSON.stringify(this.structureConfig));

        // on récupère la dernière config de l'historique
        const historyConfig = this.redoHistoryStack.pop();

        // on ajoute la config actuelle à l'historique de undo
        this.configHistoryStack.push({ config: currentConfig, metadata: { ...historyConfig.metadata } });

        this.undoStatus.emit(this.configHistoryStack.length > 0);
        this.redoStatus.emit(this.redoHistoryStack.length > 0);

        // on change la config actuelle par la config de l'historique
        this.structureConfig = historyConfig.config;

        this.codeHuntStructureForm.patchValue(this.structureConfig);

        // on met à jour les points de codes
        this.codesPoints = this.structureConfig.codesPoints;

        // on met à jour les points de codes dans les codes
        if (historyConfig?.metadata?.inputName === 'codesPoints') {
            if (this.codeHuntConfig?.codes?.length > 0) {
                this.codeHuntConfig?.codes?.forEach(code => {
                    if (code) code.points = this.codesPoints;
                });
            }
        }

        // on met à jour les points de quiz
        this.subExpQuestionPoints = this.structureConfig.subExpAnswerScore;

        // si on a des métadonnées d'input, on focus sur l'input
        if (historyConfig?.metadata?.inputName) {
            if (historyConfig.metadata.inputName === 'introText') {
                const inputElement = document.getElementsByClassName('mInput_textarea_container')[0];
                if (inputElement && inputElement?.children?.length > 0) {
                    (inputElement.children[0] as any).focus();
                }
            } else if (historyConfig.metadata.inputName !== 'codesPoints') {
                const inputElement = document.getElementById('input-' + historyConfig.metadata.inputName);
                inputElement.focus();
            }
        }

        this.saveStructure(true);

        setTimeout(() => {
            if (this.redoClick === redoClickTemp) {
                this.updatingFormGroup = false;
                this.saveStructure(true);
            }
        }, 600);
    }
}
