<!-- <div class="modal-confirm">
  <h1>
    {{modalTitle}}
  </h1>
  <button (click)="closeModal('cancel')">cancel</button>
  <button (click)="closeModal('confirm')">confirm</button>
</div> -->


<div class="container" *ngIf="displayTemplate === modalConfirmDisplayTemplate.DEFAULT">
  <div *ngIf="modalTitle" class="title">
    <h1 *ngIf="isTitleHTML" [innerHTML]="modalTitle"></h1>
    <h1 *ngIf="!isTitleHTML">{{modalTitle}}</h1>
  </div>
  <div *ngIf="modalDescription">
    <h3 *ngIf="!isDescriptionHTML">{{modalDescription}}</h3>
    <h3 *ngIf="isDescriptionHTML" [innerHTML]="modalDescription"></h3>
  </div>
  <div class="controls">
    <div class="mocli-button button cancel" (click)="closeModal('cancel')">Annuler</div>
    <div class="mocli-button button confirm" style="margin-left: 10px;" (click)="closeModal('confirm')">Confirmer</div>
  </div>
</div>


<div class="container" *ngIf="displayTemplate === modalConfirmDisplayTemplate.FINAL_SAVE">
  <div class="title">
    <h1>
      Cette validation est définitive et nécessaire pour ajouter les gains au jeu.
    </h1>
  </div>

  <div class="description">
    <h3>
      Si vous souhaitez encore apporter des modifications nous recommandons d’enregistrer
    </h3>
  </div>
  
  <div class="controls">
    <div class="mocli-button button confirm" (click)="closeModal('save')">Enregistrer</div>
    <div class="mocli-button button final-save" style="margin-left: 10px;" (click)="closeModal('final-save')">Validation définitive</div>
  </div>
</div>