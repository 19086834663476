import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { ModalTutorialCatalogComponent } from './modal-tutorial-catalog/modal-tutorial-catalog.component';
import { ModalStatsCustomPeriodComponent } from './modal-stats-custom-period/modal-stats-custom-period.component';
import { CalendarModule } from 'primeng/calendar';
import { ModalDatePickerComponent } from './modal-date-picker/modal-date-picker.component';
import { ModalOrderValidateComponent } from './modal-order-validate/modal-order-validate.component';
import { ModalBuyCreditsComponent } from './modal-buy-credits/modal-buy-credits.component';
import { SharedModule } from '../shared.module';
import { ModalRegisterResultComponent } from './modal-register-result/modal-register-result.component';
import { ModalUpdateAdressComponent } from './modal-update-adress/modal-update-adress.component';
import { ModalContactComponent } from './modal-contact/modal-contact.component';
import { ModalQRComponent } from './modal-qr/modal-qr.component';
import { ModalPreviewExperienceComponent } from './modal-preview-experience/modal-preview-experience.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ModalExtendExperienceComponent } from './modal-extend-experience/modal-extend-experience.component';
import { ModalEditDemoComponent } from './modal-edit-demo/modal-edit-demo.component';
import { ModalCropImageComponent } from './modal-crop-image/modal-crop-image.component';
import { ModalHintComponent } from './modal-hint/modal-hint.component';
import { ModalTermsComponent } from './modal-terms/modal-terms.component';
import { ModalAddOrderOptionComponent } from './modal-add-order-option/modal-add-order-option.component';
import { ModalStickerRepartitionComponent } from './modal-sticker-repartition/modal-sticker-repartition.component';
import { ModalPreviewImageComponent } from './modal-preview-image/modal-preview-image.component';
import { ModalPreviewTemplateSupportComponent } from './modal-preview-template-support/modal-preview-template-support.component';
import { ModalAddStatComponent } from './modal-add-stat/modal-add-stat.component';
import { ModalConfirmLeavingComponent } from './modal-confirm-leaving/modal-confirm-leaving.component';
import { ModalScannerErrorComponent } from './modal-scanner-error/modal-scanner-error.component';
import { ModalCodehuntGuideComponent } from './modal-codehunt-guide/modal-codehunt-guide.component';
import { TableModule } from 'primeng/table';
import { ModalLanguageComponent } from './modal-language/modal-language.component';
import { ModalAddCategoryComponent } from './modal-add-category/modal-add-category.component';
import { ModalTextInfoComponent } from './modal-text-info/modal-text-info.component';
import { TooltipModule } from 'primeng/tooltip';
import { ModalAddSectionComponent } from './modal-add-section/modal-add-section.component';
import { ModalLoginComponent } from './modal-login/modal-login.component';
import { ModalAddThemeComponent } from './modal-add-theme/modal-add-theme.component';
import { CodeHuntButtonComponent } from './modal-codehunt-guide/code-hunt-button/code-hunt-button.component';
import { ModalCreateCustomThemeComponent } from './modal-create-custom-theme/modal-create-custom-theme.component';
import { ModalDataCheckboxComponent } from './modal-data/modal-data-checkbox/modal-data-checkbox.component';
import { ModalDataMultipleComponent } from './modal-data/modal-data-multiple/modal-data-multiple.component';
import { ModalDataOpenComponent } from './modal-data/modal-data-open/modal-data-open.component';
import { ModalDataRequiredComponent } from './modal-data/modal-data-required/modal-data-required.component';
import { ModalDataUniqueComponent } from './modal-data/modal-data-unique/modal-data-unique.component';
import { ModalSimpleDatePickerComponent } from './modal-simple-date-picker/modal-simple-date-picker.component';
import { ModalConfirmContractRGPDComponent } from './modal-confirm-contract-rgpd/modal-confirm-contract-rgpd.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ModalSubExpConfiguratorComponent } from './modal-sub-exp-configurator/modal-sub-exp-configurator.component';
import { ModalConfirmPrimeComponent } from './modal-confirm-prime/modal-confirm-prime.component';
import { ModalEditObjectNameComponent } from './modal-edit-object-name/modal-edit-object-name.component';
import { InputModule } from "../../input/input.module";

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createTranslateLoader(http: HttpClient) {
  console.log('createTranslateLoader modal module');
  return new TranslateHttpLoader(http, './assets/i18n/experiences/', '.json');
}

@NgModule({
  declarations: [
    ModalConfirmComponent,
    ModalTutorialCatalogComponent,
    ModalStatsCustomPeriodComponent,
    ModalDatePickerComponent,
    ModalOrderValidateComponent,
    ModalBuyCreditsComponent,
    ModalRegisterResultComponent,
    ModalUpdateAdressComponent,
    ModalContactComponent,
    ModalQRComponent,
    ModalPreviewExperienceComponent,
    ModalExtendExperienceComponent,
    ModalEditDemoComponent,
    ModalCropImageComponent,
    ModalHintComponent,
    ModalTermsComponent,
    ModalAddOrderOptionComponent,
    ModalStickerRepartitionComponent,
    ModalPreviewImageComponent,
    ModalPreviewTemplateSupportComponent,
    ModalAddStatComponent,
    ModalConfirmLeavingComponent,
    ModalScannerErrorComponent,
    ModalCodehuntGuideComponent,
    ModalLanguageComponent,
    ModalAddCategoryComponent,
    ModalTextInfoComponent,
    ModalAddSectionComponent,
    ModalLoginComponent,
    ModalAddThemeComponent,
    CodeHuntButtonComponent,
    ModalCreateCustomThemeComponent,
    ModalDataCheckboxComponent,
    ModalDataMultipleComponent,
    ModalDataOpenComponent,
    ModalDataRequiredComponent,
    ModalDataUniqueComponent,
    ModalSimpleDatePickerComponent,
    ModalConfirmContractRGPDComponent,
    ModalSubExpConfiguratorComponent,
    ModalConfirmPrimeComponent,
    ModalEditObjectNameComponent,
  ],
  exports: [
    ModalConfirmComponent,
    ModalTutorialCatalogComponent,
    ModalStatsCustomPeriodComponent,
    ModalDatePickerComponent,
    ModalOrderValidateComponent,
    ModalBuyCreditsComponent,
    ModalRegisterResultComponent,
    ModalUpdateAdressComponent,
    ModalContactComponent,
    ModalQRComponent,
    ModalPreviewExperienceComponent,
    ModalExtendExperienceComponent,
    ModalEditDemoComponent,
    ModalCropImageComponent,
    ModalHintComponent,
    ModalTermsComponent,
    ModalAddOrderOptionComponent,
    ModalStickerRepartitionComponent,
    ModalPreviewImageComponent,
    ModalPreviewTemplateSupportComponent,
    ModalAddStatComponent,
    ModalConfirmLeavingComponent,
    ModalScannerErrorComponent,
    ModalCodehuntGuideComponent,
    ModalLanguageComponent,
    ModalAddCategoryComponent,
    ModalTextInfoComponent,
    ModalAddSectionComponent,
    ModalLoginComponent,
    ModalAddThemeComponent,
    CodeHuntButtonComponent,
    ModalCreateCustomThemeComponent,
    ModalDataCheckboxComponent,
    ModalDataMultipleComponent,
    ModalDataOpenComponent,
    ModalDataRequiredComponent,
    ModalConfirmPrimeComponent,
    ModalDataUniqueComponent
  ],
  imports: [
    TranslateModule.forChild({
        loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient]
        },
        isolate: true
    }),
    TooltipModule,
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    SharedModule,
    ImageCropperModule,
    TableModule,
    QRCodeModule,
    InputModule
]
})
export class ModalModule { }
