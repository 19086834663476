/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/dot-notation */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-modal-add-category',
  templateUrl: './modal-add-category.component.html',
  styleUrls: ['./modal-add-category.component.scss'],
})
export class ModalAddCategoryComponent implements OnInit {
  public addCategoryFormGroup: FormGroup;
  public isSubmitted: boolean = false;
  public categoryToEdit: string = null;
  public baseCategory: string[] = null;

  languages: any[] = [{name: 'Français', key: 'fr'}];
  currentLanguageIdx: number = 0;
  nameByLang: any = [];

  stepStatusLabels = {
    completed: 'Complété',
    current: 'En cours',
    waiting: 'En attente',
  };

  stepStatusColors = {
    completed: '#6CC729',
    current: '#0075F1',
    waiting: '#CECECE',
  };

  constructor(
    private fb: FormBuilder,
    private navParams: NavParams,
    private modalController: ModalController,
  ) {}

  ngOnInit() {
    if (this.navParams?.data?.categoryList) {
      this.baseCategory = this.navParams.data.categoryList;
    }
    if (this.navParams?.data?.languages) {
      this.languages = this.navParams.data.languages;

      this.languages.forEach(lang => {
        this.nameByLang[lang.key] = '';
      });
    }

    if (this.navParams?.data?.category) {
      this.categoryToEdit = this.navParams.data.category;
      this.nameByLang = this.categoryToEdit;
    }
    this.addCategoryFormGroup = this.fb.group({
      name: [this.categoryToEdit && this.categoryToEdit['fr'] ? this.categoryToEdit['fr'] : '', [Validators.required, Validators.maxLength(48)]],
    });
  }

  onAddClick() {
    this.isSubmitted = true;

    if (this.addCategoryFormGroup.invalid) return;

    if (this.baseCategory?.find(category => category.toUpperCase() === this.addCategoryFormGroup.value.name.toUpperCase())) {
      this.addCategoryFormGroup?.controls?.name?.setErrors({alreadyExists: true});
      return;
    }

    this.closeModal(this.addCategoryFormGroup.value);
  }

  async closeModal(data: any = null, role: string = 'close') {
    setTimeout(() => {
      this.addCategoryFormGroup.get('name').setValue('');
      this.isSubmitted = false;
    }, 500);
    await this.modalController.dismiss(data, role);
  }

  getStatus(idx: number) {
    if (idx < this.currentLanguageIdx) {
      return 'completed';
    } else if (idx === this.currentLanguageIdx) {
      return 'current';
    }
    return 'waiting';
  }

  nextStep() {
    this.isSubmitted = true;
    if (this.addCategoryFormGroup.invalid) return;
    this.nameByLang[this.languages[this.currentLanguageIdx]?.key] = this.addCategoryFormGroup.get('name')?.value;

    if (this.currentLanguageIdx + 1 === this.languages.length) {
      console.log('this.nameByLang', this.nameByLang);
      this.closeModal(this.nameByLang, 'confirm');
    } else {
      this.currentLanguageIdx++;
      if (this.nameByLang[this.languages[this.currentLanguageIdx]?.key]) {
        this.addCategoryFormGroup.get('name').setValue(this.nameByLang[this.languages[this.currentLanguageIdx]?.key]);
      } else {
        this.addCategoryFormGroup.get('name').setValue('');
      }
      this.isSubmitted = false;
    }
  }

  prevStep() {
    if (this.currentLanguageIdx > 0) {
      this.nameByLang[this.languages[this.currentLanguageIdx]?.key] = this.addCategoryFormGroup.get('name')?.value;

      this.currentLanguageIdx--;
      if (this.nameByLang[this.languages[this.currentLanguageIdx]?.key] !== '') {
        this.addCategoryFormGroup.get('name').setValue(this.nameByLang[this.languages[this.currentLanguageIdx]?.key]);
      } else {
        this.addCategoryFormGroup.get('name').setValue('');
      }
    } else {
      this.closeModal();
    }
  }

  setupQuestion() {
  }
}
