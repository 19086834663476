import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { StepsService } from './steps.service';
import { HeaderBarService } from 'src/app/services/HeaderBarService';
import { TranslateService } from '@ngx-translate/core';

export interface StepItem {
  label: string;

  disable?: boolean;
  clickable?: boolean;
  noBadge?: boolean;
  permanentStatus?: 'completed' | 'current' | 'waiting' | 'other';
  checkClickable?: (language: string) => boolean | Promise<boolean>;

  hasSeenSystem?: boolean;
  hasBeenSeen?: boolean;

  language?: string;
  index?: number;
  hasError?: boolean;
}

export interface StepsConfig {
  steps: StepItem[];

  horizontalAlign?: 'flex-start' | 'center' | 'flex-end';
  verticalAlign?: 'flex-start' | 'center' | 'flex-end';
  width?: 'auto' | '100%' | 'fit-content';
}



@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements AfterViewInit {
  @Input() config: StepsConfig = null;

  stepStatusLabels = {
    completed: 'Complété',
    current: 'En cours',
    waiting: 'En attente',
  };

  stepStatusColors = {
    completed: '#6CC729',
    current: '#0075F1',
    waiting: '#CECECE',
    other: '#FFB649'
  };

  private baseConfig: StepsConfig = {
    steps: [
      {
        label: '',
      }
    ],
    verticalAlign: 'center',
    horizontalAlign: 'center',
    width: '100%',
  };

  constructor(
    public stepsService: StepsService,
    private headerBarService: HeaderBarService,
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.config = {...this.baseConfig, ...this.config};
      this.stepsService.totalSteps = this.config?.steps?.length ?? 0;
    });
  }

  getStatus(idx: number) {
    const step = this.headerBarService.onHeaderBarChanged.value?.stepsConfig?.steps[idx];

    if (step?.permanentStatus) {
      const permanentStatus = this.headerBarService.onHeaderBarChanged.value.stepsConfig.steps[idx].permanentStatus;
      return permanentStatus;
    }

    if (idx === this.stepsService.currentStepIdx) {
      return 'current';
    }

    if (step?.hasSeenSystem === true && step?.hasBeenSeen === true) {
      if (step?.hasError) {
        return 'waiting';
      } else {
        return 'completed';
      }
    }

    if (step?.hasError) {
      return 'waiting';
    }

    if (idx < this.stepsService.currentStepIdx && !step.hasSeenSystem) {
      return 'completed';
    }
    return 'waiting';
  }

  goToStep(index: number) {
    this.stepsService.goToStep(index);
  }

  onStepClick(step: StepItem, idx: number) {
    this.goToStep(idx);
    return;
    // if (step.clickable && !step.disable) {
    // }

    // if (step?.checkClickable !== null && step?.checkClickable !== undefined) {
    //   const language = step.language;

    //   if (step.checkClickable(language)) {
    //     this.goToStep(idx);
    //     return;
    //   }
    // }
  }

}
