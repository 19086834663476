<div class="content-editor-main-container">

    <!------ Étape 1 ------>
    <!-- Structure -->
    <ng-container *ngIf="stepsService.currentStepIdx === 0">
        <app-codehunt-structure (undoStatus)="undoDisabled = $event === false" (redoStatus)="redoDisabled = $event === false" [undoRedoSubject]="undoRedoSubject" (autoSaving)="autoSavingTriggered($event)" [config]="config" [changesSubject]="changesSubject" [orderId]="orderId"></app-codehunt-structure>
    </ng-container>
    
    
    <!------ Étape 2 ------>
    <!-- Édition des QR Codes -->
    <ng-container *ngIf="stepsService.currentStepIdx === 1 && isMultiQuiz === false">
        <app-codehunt-content-editor [undoRedoSubject]="undoRedoSubject" [structureConfig]="structureConfig" (undoStatus)="undoDisabled = $event === false" (redoStatus)="redoDisabled = $event === false" (autoSaving)="autoSavingTriggered($event)" [config]="config" [codeHuntConfig]="codeHuntConfig" [changesSubject]="changesSubject" [orderId]="orderId"></app-codehunt-content-editor>
    </ng-container>

    <!-- Édition des quiz -->
    <ng-container *ngIf="stepsService.currentStepIdx === 1 && isMultiQuiz === true">
        <app-multiquiz-content-editor [undoRedoSubject]="undoRedoSubject" [structureConfig]="structureConfig" (undoStatus)="undoDisabled = $event === false" (redoStatus)="redoDisabled = $event === false" (autoSaving)="autoSavingTriggered($event)" [config]="config" [codeHuntConfig]="codeHuntConfig" [changesSubject]="changesSubject" [orderId]="orderId"></app-multiquiz-content-editor>
    </ng-container>
</div>

<div class="content-editor-controls-container">
    <div class="undo-redo-container" [ngClass]="{'undo-redo-container-quiz': isMultiQuiz}">
        <div class="undo-container" (click)="undoRedoSubject.next('undo')" [ngClass]="{'undo-container-disabled': undoDisabled}">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.2875 2.95996V1.53125C1.2875 1.4054 1.24511 1.29275 1.16368 1.21132C1.08225 1.12989 0.9696 1.0875 0.84375 1.0875C0.7179 1.0875 0.605255 1.12989 0.523821 1.21132C0.442386 1.29275 0.4 1.4054 0.4 1.53125V4.28125C0.4 4.4071 0.442386 4.51975 0.523821 4.60118C0.605255 4.68261 0.7179 4.725 0.84375 4.725H3.59375C3.7196 4.725 3.83225 4.68261 3.91368 4.60118C3.99511 4.51975 4.0375 4.4071 4.0375 4.28125C4.0375 4.1554 3.99511 4.04275 3.91368 3.96132C3.83225 3.87989 3.7196 3.8375 3.59375 3.8375H1.82769C2.59895 2.3025 4.23597 1.2875 6 1.2875C8.62602 1.2875 10.7125 3.37398 10.7125 6C10.7125 8.62602 8.62602 10.7125 6 10.7125C4.72009 10.7125 3.57838 10.2418 2.70453 9.3007L2.70458 9.30066L2.70196 9.29804C2.52541 9.12149 2.25584 9.12149 2.07929 9.29804C1.90314 9.47419 1.90274 9.74294 2.07809 9.91951C3.12914 11.04 4.53061 11.6 6 11.6C9.08023 11.6 11.6 9.08023 11.6 6C11.6 2.91977 9.08023 0.4 6 0.4C4.09242 0.4 2.30897 1.38862 1.2875 2.95996Z" fill="#848484" stroke="#848484" stroke-width="0.2"/>
            </svg>
        </div>
        <div class="redo-container" (click)="undoRedoSubject.next('redo')" [ngClass]="{'undo-container-disabled': redoDisabled}">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.7125 2.95996V1.53125C10.7125 1.4054 10.7549 1.29275 10.8363 1.21132C10.9178 1.12989 11.0304 1.0875 11.1562 1.0875C11.2821 1.0875 11.3947 1.12989 11.4762 1.21132C11.5576 1.29275 11.6 1.4054 11.6 1.53125V4.28125C11.6 4.4071 11.5576 4.51975 11.4762 4.60118C11.3947 4.68261 11.2821 4.725 11.1562 4.725H8.40625C8.2804 4.725 8.16775 4.68261 8.08632 4.60118C8.00489 4.51975 7.9625 4.4071 7.9625 4.28125C7.9625 4.1554 8.00489 4.04275 8.08632 3.96132C8.16775 3.87989 8.2804 3.8375 8.40625 3.8375H10.1723C9.40105 2.3025 7.76403 1.2875 6 1.2875C3.37398 1.2875 1.2875 3.37398 1.2875 6C1.2875 8.62602 3.37398 10.7125 6 10.7125C7.27991 10.7125 8.42162 10.2418 9.29547 9.3007L9.29542 9.30066L9.29804 9.29804C9.47459 9.12149 9.74416 9.12149 9.92071 9.29804C10.0969 9.47419 10.0973 9.74294 9.92191 9.91951C8.87086 11.04 7.46939 11.6 6 11.6C2.91977 11.6 0.4 9.08023 0.4 6C0.4 2.91977 2.91977 0.4 6 0.4C7.90758 0.4 9.69103 1.38862 10.7125 2.95996Z" fill="#848484" stroke="#848484" stroke-width="0.2"/>
            </svg>
        </div>
    </div>
    
    <div class="auto-save-container">
        <div class="auto-save-element" [ngClass]="{'auto-save-element-active': hasAutoSaved && !isAutoSaving}">
            Sauvegarde automatique réussie.
        </div>

        <div class="auto-save-element loader-container" [ngClass]="{'auto-save-element-active': isAutoSaving}">
            <ion-spinner color="dark"></ion-spinner>
        </div>
    </div>

    <div class="global-btn global-btn-row global-btn-back" *ngIf="stepsService?.currentStepIdx > 0" (click)="onPrevClick()">
        <div class="img-btn">
            <img src="https://cdn.mocli.fr/icon/btn-arrow-left.svg" alt="">
        </div>
        Structure
    </div>

    <!-- <div class="global-btn global-btn-save" [ngClass]="{'global-btn-save-disabled': changesSubject?.value === false}" (click)="onSaveClick()">
        Enregistrer
    </div> -->

    <div class="global-btn global-btn-row global-btn-back" *ngIf="(stepsService.currentStepIdx + 1) < stepsService.totalSteps" (click)="onNextClick()">
        QR Codes
        <div class="img-btn">
            <img src="https://cdn.mocli.fr/icon/btn-arrow-right.svg" alt="">
        </div>
    </div>
    
</div>