// create template of module angular
import { NgModule } from '@angular/core';
import { InputSelectComponent } from './input-select/input-select.component';
import { InputTextComponent } from './input-text/input-text.component';
import { InputTextareaComponent } from './input-textarea/input-textarea.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { InputLabelComponent } from './input-label/input-label.component';
import { EditButtonComponent } from '../button/edit-button/edit-button.component';

@NgModule({
  declarations: [
    InputSelectComponent,
    InputTextComponent,
    InputTextareaComponent,
    InputLabelComponent,
    EditButtonComponent
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule
  ],
  exports: [
    InputSelectComponent,
    InputTextComponent,
    InputTextareaComponent,
    InputLabelComponent,
    EditButtonComponent
  ],
})
export class InputModule {}
